import React, { FC, useRef } from 'react';
import './FrontPage.scss';

const FrontPage: FC<{}> = () => {
    const [descriptionRef, servicesRef, contactsRef, hiringRef] =
     [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];

    const scrollToRef = (ref: any) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    };

    const executeScroll = (ref: any) => {
        scrollToRef(ref);
    };

    const menuElement = document.getElementById('menu');
    const menuOffset = menuElement?.offsetTop || 0;
    window.onscroll = () => {
        if (window.pageYOffset > menuOffset) {
            document.getElementById('menu')?.classList.add('sticky');
        } else {
            document.getElementById('menu')?.classList.remove('sticky');
        }
    }

    return (
        <div className='container'>
            <div className='mobile__container'>
                <div className='menu' id='menu'>
                    <nav>
                        <a className='menu__item' onClick={() => executeScroll(descriptionRef)}>description</a>
                        <a className='menu__item' onClick={() => executeScroll(servicesRef)}>services</a>
                        <a className='menu__item' onClick={() => executeScroll(hiringRef)}>hiring</a>
                        <a className='menu__item' onClick={() => executeScroll(contactsRef)}>contacts</a>
                    </nav>
                </div>
                <div className='description' ref={descriptionRef}>
                    <div className='description__header'>
                        <p className='description__header-title'>
                            DEDA
                        </p>
                        <p className='description__header-subtitle'>
                            The center of cloud integration.
                        </p>
                    </div>
                    <div className='description__content-container'>
                        <p className='description__content'>
                            Deda is an innovative company specializing in creating services with SaaS (Software as a Service) technology.
                        </p>
                        <p className='description__content'>
                            We've worked for many years in the banking sector, have helped implement major IT projects, and have been able to appreciate the advantages
                            of using professional software. However, not every financial institution is prepared to increase its outlays every year on expensive IT
                            projects to respond to market changes. The SaaS concept comes to the rescue.
                        </p>
                        <p className='description__content'>
                            We choose the best software from leading companies and use cloud technologies to offer it in the form of services affordable to any financial organization
                        </p>
                        <p className='description__content-epilogue'>
                            We make the best affordable.
                        </p>
                    </div>
                </div>
            </div>

            <div className="hiring" ref={servicesRef}>
                <div className='hiring__title'>Our Services</div>
            </div>

            <div className="service">
                <p className='service__title'>Check Service</p>
                <p className='service__content'>
                A service needed by financial organizations working in the mass credit market.
                It lets you to create and adjust policies for decision-making, scoring assessment, and selecting an appropriate credit product.
                </p>
            </div>
            <div className="service">
                <p className='service__title'>Connection</p>
                <p className='service__content'>
                Solution, which provides a single "window" for placement with data providers as part of the reliability assessment processes
                 (scoring, underwriting, verification), incl. in lending and insurance. The unified request format allows Connection to be painlessly
                 integrated into the processes and take full advantage of its work when using a large number of information systems.
                </p>
            </div>
            <div className="service">
                <p className='service__title'>Surfing</p>
                <p className='service__content'>
                Surfing  is a comprehensive solution for automatisation the business processes of microfinance companies.
                The Surfing  provides life cycle of applications from registration, to accounting procedure, verification and loan support.
                The solution is represents of modules, thats why, it is universal in employment and suitable for compnies of any size.
                </p>
            </div>

            <div className="service">
                <p className='service__title'>Deployment</p>
                <p className='service__content'>
                In designing our services we created the most effective standard processes that are generally suitable for all financial organizations.
                This has allowed us to reduce the time and cost of deploying our services. When a financial organizations joins any of our "cloud" services,
                we work closely with financial organization staff to ensure that they can comfortably use these services after launching them in commercial operation.
                </p>
            </div>

            <div className="service">
                <p className='service__title'>Consulting</p>
                <p className='service__content'>
                Financial organization often contact us wanting to use our service for launching a new product on the market.
                 In such cases, we are ready to provide additional consulting services. Perfecting credit strategy, developing individual scorecards,
                  analyzing credit history, integrating services with banking systems and customer relationship management systems — this is far
                   from a complete list of our additional capabilities.
                </p>
            </div>

            <div className="hiring" ref={hiringRef}>
                <div className='hiring__title'>Hiring</div>

                <div className="service">
                    <p className='service__title'>Junior Java-developer</p>
                    <p className='service__content'>
                        <p className='skills__title'>
                            Dynamic team is looking for a Junior Java Developer with some experience in Java to help to modernize and support existing
                            applications and create a new one.
                        </p>
                        <p className='skills__title'>Functional and interpersonal skills:</p>
                        <ul className='skills__list'>
                            <li>Higher or incomplete higher specialized education is mandatory;</li>
                            <li>Experience with web services;</li>
                            <li>Good OOP knowledge is a “must”;</li>
                            <li>Experience with a source control management solution;</li>
                            <li>Experience working with SQL;</li>
                            <li>The ability to quickly understand a large amount of legacy code;</li>
                            <li>The ability to learn and explore new technologies.</li>
                        </ul>
                    </p>
                </div>
                <div className="service">
                    <p className='service__title'>Junior Frontend-developer</p>
                    <p className='service__content'>
                        <p>We are looking for a Junior Frontend Developer to join our team.</p>

                        <p className='skills__title'>Functional and interpersonal skills:</p>
                        <ul className='skills__list'>
                            <li>Bachelor's degree in Computer Science or related field;</li>
                            <li>Experience with modern HTML, CSS, JavaScript (ES6+), Typescript and writing clean and developer friendly code;</li>
                            <li>Experience with a source control management solution;</li>
                            <li>Good collaboration, teamwork, and learning skills;</li>
                            <li>Knowledge of Java Core (Collections, Concurrency);</li>
                            <li>Nice to have Spring Framework (MVC, Security) and Hibernate skills.</li>
                        </ul>

                        <p className='skills__title'>Nice to have:</p>
                        <ul className='skills__list'>
                            <li>Experience with application frameworks (Angular, React, etc) and CSS frameworks;</li>
                            <li>Experience building web applications and integrations using REST.</li>
                        </ul>
                    </p>
                </div>
            </div>

            <div className="service __contact" ref={contactsRef}>
                <p className='service__title'>Contacts</p>
                <p className='service__content'>
                    <span className='text-bold'>mail</span>: info@deda.solutions
                </p>
                <p className="service__content">
                    <span className='text-bold'>address</span>: Dubai Silicon Oasis, DDP, Building A2, Dubai, United Arab Emirates
                </p>
                <p className="service__content">
                    <span className='text-bold'>DEDA</span> - FZCO Limited Liability Company
                </p>
            </div>
        </div>
    );
}

export default FrontPage;
