import React from 'react';
import FrontPage from './components/FrontPage';
import './styles/index.scss';


function App() {
    return (
        <div className='app-wrapper'>
            <FrontPage/>
        </div>
    );
}

export default App;